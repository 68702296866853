// This code was generated by a tool. Do not edit manually.
import { BaseClient, createRequestInit } from './GraphQL.BaseClient';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Char: string;
  DateTime: Date;
  DateTimeOffset: Date;
  Long: number;
  Short: number;
}

export interface AccessToken {
  expiresAt?: Maybe<Scalars['DateTimeOffset']>;
  grantType?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export enum AccessTypes {
  Application = 'APPLICATION',
  AutoPay = 'AUTO_PAY',
  BuyUp = 'BUY_UP',
  ChangeDueDate = 'CHANGE_DUE_DATE',
  ChangeFundingMethod = 'CHANGE_FUNDING_METHOD',
  ChangePassword = 'CHANGE_PASSWORD',
  Customer = 'CUSTOMER',
  Documents = 'DOCUMENTS',
  FullAccess = 'FULL_ACCESS',
  Messages = 'MESSAGES',
  None = 'NONE',
  Payment = 'PAYMENT',
  PaymentReauthorization = 'PAYMENT_REAUTHORIZATION',
  Refinance = 'REFINANCE',
  TellerAccess = 'TELLER_ACCESS',
  Unauthenticated = 'UNAUTHENTICATED'
}

export interface AccountDetailsAuthenticationInputModel {
  birthDay?: InputMaybe<Scalars['Int']>;
  birthMonth?: InputMaybe<Scalars['Int']>;
  birthYear?: InputMaybe<Scalars['Int']>;
  loanType?: InputMaybe<Scalars['String']>;
  riKey?: InputMaybe<Scalars['Int']>;
  riLocation?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
}

export interface AccountDetailsAuthenticationViewModel {
  isError: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface AccountSetupModel {
  email?: Maybe<Scalars['String']>;
  hidePasswordFields: Scalars['Boolean'];
  isSecurityQuestionRequired: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  securityAnswer?: Maybe<Scalars['String']>;
  securityQuestion?: Maybe<Scalars['Int']>;
  securityQuestions?: Maybe<Array<Maybe<StringInt32KeyValuePairGraphType>>>;
  verifyPassword?: Maybe<Scalars['String']>;
}

export interface AccountSetupModelInput {
  email?: InputMaybe<Scalars['String']>;
  hidePasswordFields?: InputMaybe<Scalars['Boolean']>;
  isSecurityQuestionRequired?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  securityAnswer?: InputMaybe<Scalars['String']>;
  securityQuestion?: InputMaybe<Scalars['Int']>;
  securityQuestions?: InputMaybe<Array<InputMaybe<StringInt32KeyValuePairInputGraphType>>>;
  verifyPassword?: InputMaybe<Scalars['String']>;
}

export interface AccountSummaryModel {
  currentState: State;
  hasInvalidEmail: Scalars['Boolean'];
  isNewLoanEnabled: Scalars['Boolean'];
  offerPaperlessBilling: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
}

export enum AdAstraContactReason {
  Complaint = 'COMPLAINT',
  Dispute = 'DISPUTE',
  GeneralQuestionsInformation = 'GENERAL_QUESTIONS_INFORMATION',
  None = 'NONE',
  PositiveFeedback = 'POSITIVE_FEEDBACK'
}

export interface AdAstraContactUsInputModel {
  body?: InputMaybe<Scalars['String']>;
  contactReason?: InputMaybe<AdAstraContactReason>;
  customerCaptchaInput?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  emailConsent?: InputMaybe<Scalars['Boolean']>;
  fileUpload?: InputMaybe<FileModelInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  rIKey?: InputMaybe<Scalars['String']>;
  smsConsent?: InputMaybe<Scalars['Boolean']>;
}

export interface AdAstraContactUsViewModel {
  emailConsentNeeded: Scalars['Boolean'];
  smsConsentNeeded: Scalars['Boolean'];
}

export interface AddressModel {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export interface AddressModelInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  unitType?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface BankAccountModel {
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountNumberVerify?: Maybe<Scalars['String']>;
  bankInstitutionNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankTransitNumber?: Maybe<Scalars['String']>;
}

export interface BankAccountModelInput {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountNumberVerify?: InputMaybe<Scalars['String']>;
  bankInstitutionNumber?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankTransitNumber?: InputMaybe<Scalars['String']>;
}

export interface CardAuthorizationModel {
  cardsOnFile?: Maybe<Array<Maybe<CardViewModel>>>;
  hideDecline: Scalars['Boolean'];
  isPaymentAuthorizationPromptNeeded: Scalars['Boolean'];
  recordDecline: Scalars['Boolean'];
  showOnPageLoad: Scalars['Boolean'];
}

export interface CardAuthorizationModelInput {
  cardsOnFile?: InputMaybe<Array<InputMaybe<CardViewModelInput>>>;
  hideDecline?: InputMaybe<Scalars['Boolean']>;
  isPaymentAuthorizationPromptNeeded?: InputMaybe<Scalars['Boolean']>;
  recordDecline?: InputMaybe<Scalars['Boolean']>;
  showOnPageLoad?: InputMaybe<Scalars['Boolean']>;
}

export interface CardViewModel {
  bin?: Maybe<Scalars['String']>;
  brand: CreditCardBrand;
  bridgePayPermanentToken?: Maybe<Scalars['String']>;
  cardExpiry?: Maybe<Scalars['DateTime']>;
  cardNumberToken?: Maybe<Scalars['String']>;
  cardToken: Scalars['Int'];
  key?: Maybe<KeyModel>;
  lastFour?: Maybe<Scalars['String']>;
}

export interface CardViewModelInput {
  bin?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<CreditCardBrand>;
  bridgePayPermanentToken?: InputMaybe<Scalars['String']>;
  cardExpiry?: InputMaybe<Scalars['DateTime']>;
  cardNumberToken?: InputMaybe<Scalars['String']>;
  cardToken?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<KeyModelInput>;
  lastFour?: InputMaybe<Scalars['String']>;
}

export interface CommunicationConsentsInputModel {
  consentToEmail?: InputMaybe<Scalars['Boolean']>;
  consentToSms?: InputMaybe<Scalars['Boolean']>;
}

export interface CommunicationConsentsViewModel {
  email?: Maybe<Scalars['String']>;
  hasEmailConsent: Scalars['Boolean'];
  hasSmsConsent: Scalars['Boolean'];
  mobilePhone?: Maybe<Scalars['String']>;
}

export interface CommunicationHistoryViewModel {
  messages?: Maybe<Array<Maybe<CommunicationMessageModel>>>;
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface CommunicationMessageModel {
  address?: Maybe<Scalars['String']>;
  attachmentFileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  body?: Maybe<Scalars['String']>;
  dateEntered: Scalars['DateTime'];
  formLetterPrintedKey: Scalars['Long'];
  fromAddress?: Maybe<Scalars['String']>;
  messageKey: Scalars['Long'];
  subject?: Maybe<Scalars['String']>;
}

export interface CommunicationPreferencesModel {
  accessType: AccessTypes;
  channels?: Maybe<Array<Maybe<PreferenceChannel>>>;
  email?: Maybe<Scalars['String']>;
  hasConsentToEmailPaymentNotices: Scalars['Boolean'];
  messageId?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  needsEmailConsent: Scalars['Boolean'];
  needsPhoneConsent: Scalars['Boolean'];
  offerPaperlessBilling: Scalars['Boolean'];
  phoneListItems?: Maybe<Array<Maybe<StringStringKeyValuePairGraphType>>>;
  preferences?: Maybe<Array<Maybe<PreferenceModel>>>;
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface CommunicationPreferencesModelInput {
  accessType?: InputMaybe<AccessTypes>;
  channels?: InputMaybe<Array<InputMaybe<PreferenceChannel>>>;
  email?: InputMaybe<Scalars['String']>;
  hasConsentToEmailPaymentNotices?: InputMaybe<Scalars['Boolean']>;
  messageId?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  needsEmailConsent?: InputMaybe<Scalars['Boolean']>;
  needsPhoneConsent?: InputMaybe<Scalars['Boolean']>;
  offerPaperlessBilling?: InputMaybe<Scalars['Boolean']>;
  phoneListItems?: InputMaybe<Array<InputMaybe<StringStringKeyValuePairInputGraphType>>>;
  preferences?: InputMaybe<Array<InputMaybe<PreferenceModelInput>>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
}

export enum CommunicationType {
  /** Marketing */
  Marketing = 'MARKETING',
  None = 'NONE',
  /** Operational */
  Operational = 'OPERATIONAL',
  /** Required Payment Notices */
  RequiredPaymentNotices = 'REQUIRED_PAYMENT_NOTICES'
}

export interface ConfirmBankingModel {
  bankName?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  showDecisionLogic: Scalars['Boolean'];
  showEmailUs: Scalars['Boolean'];
  showYodlee: Scalars['Boolean'];
}

export interface ConsentToEmailPaymentNoticesResult {
  hasConsent: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
}

export enum ContactReason {
  Collections = 'COLLECTIONS',
  Complaint = 'COMPLAINT',
  FraudIdTheft = 'FRAUD_ID_THEFT',
  GeneralQuestionsFeedback = 'GENERAL_QUESTIONS_FEEDBACK',
  IncomeVerification = 'INCOME_VERIFICATION',
  MobileAppProblem = 'MOBILE_APP_PROBLEM',
  None = 'NONE',
  PositiveFeedback = 'POSITIVE_FEEDBACK',
  RequestDueDateChange = 'REQUEST_DUE_DATE_CHANGE',
  WebsiteProblem = 'WEBSITE_PROBLEM'
}

export interface ContactUsInputModel {
  body?: InputMaybe<Scalars['String']>;
  contactReason?: InputMaybe<ContactReason>;
  fileUpload?: InputMaybe<FileModelInput>;
  subject?: InputMaybe<Scalars['String']>;
}

export interface ContactUsResponseModel {
  emailSent: Scalars['Boolean'];
}

export enum CreditCardBrand {
  /** All Start CPC */
  AllStarCpc = 'ALL_STAR_CPC',
  /** American Express */
  AmericanExpress = 'AMERICAN_EXPRESS',
  /** Amex CPC */
  AmexCpc = 'AMEX_CPC',
  /** BA Air Plus */
  BaAirPlus = 'BA_AIR_PLUS',
  /** BHS Gold */
  BhsGold = 'BHS_GOLD',
  /** BTTestHost */
  BtTestHost = 'BT_TEST_HOST',
  /** Burton Menswear */
  BurtonMenswear = 'BURTON_MENSWEAR',
  /** CAF */
  Caf = 'CAF',
  /** Clydesdale */
  Clydesdale = 'CLYDESDALE',
  /** Creation */
  Creation = 'CREATION',
  /** Diners */
  Diners = 'DINERS',
  /** Discover */
  Discover = 'DISCOVER',
  /** EDC Maestro */
  EdcMaestro = 'EDC_MAESTRO',
  /** Electron */
  Electron = 'ELECTRON',
  /** JCB */
  Jcb = 'JCB',
  /** Laser */
  Laser = 'LASER',
  /** LTF */
  Ltf = 'LTF',
  /** Maestro */
  Maestro = 'MAESTRO',
  /** MasterCard */
  MasterCard = 'MASTER_CARD',
  /** Mother Care */
  MothercareCard = 'MOTHERCARE_CARD',
  /** NetSpend */
  NetSpendGiftCard = 'NET_SPEND_GIFT_CARD',
  /** MasterCard */
  NetSpendMasterCard = 'NET_SPEND_MASTER_CARD',
  /** Visa */
  NetSpendVisa = 'NET_SPEND_VISA',
  None = 'NONE',
  /** Selex Visa */
  SelexVisa = 'SELEX_VISA',
  /** Solo */
  Solo = 'SOLO',
  /** Time */
  Time = 'TIME',
  /** Visa */
  Visa = 'VISA',
  /** Visa CPC */
  VisaCpc = 'VISA_CPC',
  /** Visa Debit */
  VisaDebit = 'VISA_DEBIT'
}

export interface DateModel {
  assumeLastDayOfMonth?: Maybe<Scalars['Boolean']>;
  base?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
}

export interface DateModelInput {
  assumeLastDayOfMonth?: InputMaybe<Scalars['Boolean']>;
  base?: InputMaybe<Scalars['DateTime']>;
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}

export interface DocumentListModel {
  currentState: State;
  documents?: Maybe<Array<Maybe<DocumentModel>>>;
  exitPromptEnabled: Scalars['Boolean'];
  fullNameMatch?: Maybe<Scalars['String']>;
  isInsuredProduct: Scalars['Boolean'];
  name?: Maybe<NameModel>;
  phone?: Maybe<Scalars['String']>;
  phoneListItems?: Maybe<Array<Maybe<SelectListItem>>>;
  redirectUrl?: Maybe<Scalars['String']>;
  refinanceLoanKey: Scalars['Int'];
  signatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  subheading?: Maybe<Scalars['String']>;
}

export interface DocumentListModelInput {
  currentState?: InputMaybe<State>;
  documents?: InputMaybe<Array<InputMaybe<DocumentModelInput>>>;
  exitPromptEnabled?: InputMaybe<Scalars['Boolean']>;
  fullNameMatch?: InputMaybe<Scalars['String']>;
  isInsuredProduct?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<NameModelInput>;
  phone?: InputMaybe<Scalars['String']>;
  phoneListItems?: InputMaybe<Array<InputMaybe<SelectListItemInput>>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  refinanceLoanKey?: InputMaybe<Scalars['Int']>;
  signatures?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subheading?: InputMaybe<Scalars['String']>;
}

export interface DocumentModel {
  documentIndex: Scalars['Int'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  isMarketingConsent: Scalars['Boolean'];
  isMilitaryDoc: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  noSignatureAction: NoSignatureAction;
  required: Scalars['Boolean'];
  showSignatureCheckbox: Scalars['Boolean'];
  signatureLabel?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface DocumentModelInput {
  documentIndex?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isMarketingConsent?: InputMaybe<Scalars['Boolean']>;
  isMilitaryDoc?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  noSignatureAction?: InputMaybe<NoSignatureAction>;
  required?: InputMaybe<Scalars['Boolean']>;
  showSignatureCheckbox?: InputMaybe<Scalars['Boolean']>;
  signatureLabel?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
}

export interface EditAccountSecurityModel {
  currentEmail?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
  currentSecurityQuestionText?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  requireCurrentPassword: Scalars['Boolean'];
  securityAnswer?: Maybe<Scalars['String']>;
  securityQuestion?: Maybe<Scalars['Int']>;
  securityQuestionListItems?: Maybe<Array<Maybe<StringInt32KeyValuePairGraphType>>>;
  showEmailVerification: Scalars['Boolean'];
  tabs?: Maybe<MyProfileTabsModel>;
  verifyEmail?: Maybe<Scalars['String']>;
  verifyPassword?: Maybe<Scalars['String']>;
}

export interface EditAccountSecurityModelInput {
  currentEmail?: InputMaybe<Scalars['String']>;
  currentPassword?: InputMaybe<Scalars['String']>;
  currentSecurityQuestionText?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  requireCurrentPassword?: InputMaybe<Scalars['Boolean']>;
  securityAnswer?: InputMaybe<Scalars['String']>;
  securityQuestion?: InputMaybe<Scalars['Int']>;
  securityQuestionListItems?: InputMaybe<Array<InputMaybe<StringInt32KeyValuePairInputGraphType>>>;
  showEmailVerification?: InputMaybe<Scalars['Boolean']>;
  tabs?: InputMaybe<MyProfileTabsModelInput>;
  verifyEmail?: InputMaybe<Scalars['String']>;
  verifyPassword?: InputMaybe<Scalars['String']>;
}

export interface EditBankingModel {
  action?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountModel>;
  canAddCard: Scalars['Boolean'];
  canChangeBankAccount: Scalars['Boolean'];
  cardAuthorizationDialog?: Maybe<CardAuthorizationModel>;
  cardsOnFile?: Maybe<Array<Maybe<CardViewModel>>>;
  confirmAddCard: Scalars['Boolean'];
  editableCard?: Maybe<CardViewModel>;
  formToShow?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<AddressModel>;
  isPaymentAuthDocAvailable: Scalars['Boolean'];
  isPaymentAuthorizationNeeded: Scalars['Boolean'];
  newBankAccount?: Maybe<BankAccountModel>;
  paperlessBillingEnabled: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  showLoanPaymentAuthorization: Scalars['Boolean'];
  tabs?: Maybe<MyProfileTabsModel>;
  testTools?: Maybe<TestToolsViewModel>;
  useHomeAddressForCard: Scalars['Boolean'];
}

export interface EditBankingModelInput {
  action?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<BankAccountModelInput>;
  canAddCard?: InputMaybe<Scalars['Boolean']>;
  canChangeBankAccount?: InputMaybe<Scalars['Boolean']>;
  cardAuthorizationDialog?: InputMaybe<CardAuthorizationModelInput>;
  cardsOnFile?: InputMaybe<Array<InputMaybe<CardViewModelInput>>>;
  confirmAddCard?: InputMaybe<Scalars['Boolean']>;
  editableCard?: InputMaybe<CardViewModelInput>;
  formToShow?: InputMaybe<Scalars['String']>;
  homeAddress?: InputMaybe<AddressModelInput>;
  isPaymentAuthDocAvailable?: InputMaybe<Scalars['Boolean']>;
  isPaymentAuthorizationNeeded?: InputMaybe<Scalars['Boolean']>;
  newBankAccount?: InputMaybe<BankAccountModelInput>;
  paperlessBillingEnabled?: InputMaybe<Scalars['Boolean']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  showLoanPaymentAuthorization?: InputMaybe<Scalars['Boolean']>;
  tabs?: InputMaybe<MyProfileTabsModelInput>;
  testTools?: InputMaybe<TestToolsViewModelInput>;
  useHomeAddressForCard?: InputMaybe<Scalars['Boolean']>;
}

export interface EditContactInfoModel {
  address?: Maybe<AddressModel>;
  birthDate?: Maybe<DateModel>;
  cellPhone?: Maybe<Scalars['String']>;
  communicationConsentOperational: Scalars['Boolean'];
  communicationConsentPromotional: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  homePhone?: Maybe<Scalars['String']>;
  housing?: Maybe<Housing>;
  identification?: Maybe<IdentificationModel>;
  lastName?: Maybe<Scalars['String']>;
  middleInitial?: Maybe<Scalars['String']>;
  nIN?: Maybe<Scalars['String']>;
  needsConsent: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  residenceSince?: Maybe<DateModel>;
  tabs?: Maybe<MyProfileTabsModel>;
  unableToEdit: Scalars['Boolean'];
}

export interface EditContactInfoModelInput {
  address?: InputMaybe<AddressModelInput>;
  birthDate?: InputMaybe<DateModelInput>;
  cellPhone?: InputMaybe<Scalars['String']>;
  communicationConsentOperational?: InputMaybe<Scalars['Boolean']>;
  communicationConsentPromotional?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  homePhone?: InputMaybe<Scalars['String']>;
  housing?: InputMaybe<Housing>;
  identification?: InputMaybe<IdentificationModelInput>;
  lastName?: InputMaybe<Scalars['String']>;
  middleInitial?: InputMaybe<Scalars['String']>;
  nIN?: InputMaybe<Scalars['String']>;
  needsConsent?: InputMaybe<Scalars['Boolean']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  residenceSince?: InputMaybe<DateModelInput>;
  tabs?: InputMaybe<MyProfileTabsModelInput>;
  unableToEdit?: InputMaybe<Scalars['Boolean']>;
}

export interface EditSpouseModel {
  address?: Maybe<AddressModel>;
  firstName?: Maybe<Scalars['String']>;
  isSameAddressAsSpouse: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  maritalStatus: MaritalStatus;
  primaryAddress?: Maybe<AddressModel>;
  redirectUrl?: Maybe<Scalars['String']>;
  tabs?: Maybe<MyProfileTabsModel>;
}

export interface EditSpouseModelInput {
  address?: InputMaybe<AddressModelInput>;
  firstName?: InputMaybe<Scalars['String']>;
  isSameAddressAsSpouse?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  primaryAddress?: InputMaybe<AddressModelInput>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  tabs?: InputMaybe<MyProfileTabsModelInput>;
}

export interface EmailVerificationModel {
  codeVerified: Scalars['Boolean'];
  maskedEmail?: Maybe<Scalars['String']>;
  messageDeliverSuccess?: Maybe<Scalars['Boolean']>;
  messageKeys?: Maybe<Array<Maybe<Scalars['Long']>>>;
  redirect?: Maybe<Scalars['String']>;
  verificationCode?: Maybe<Scalars['String']>;
  verificationCodeRecipientEmail?: Maybe<Scalars['String']>;
  verificationCodeSent: Scalars['Boolean'];
}

export interface FileModelInput {
  base64?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
}

export interface GetContactUsResponseModel {
  body?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
}

export enum Housing {
  None = 'NONE',
  Own = 'OWN',
  Rent = 'RENT'
}

export interface IdentificationModel {
  country?: Maybe<Scalars['String']>;
  countryListItems?: Maybe<Array<Maybe<SelectListItem>>>;
  countryRequiredTypeKeys?: Maybe<Array<Maybe<Scalars['Int']>>>;
  expirationRequiredTypeKeys?: Maybe<Array<Maybe<Scalars['Int']>>>;
  expiry?: Maybe<DateModel>;
  number?: Maybe<Scalars['String']>;
  numberRequiredTypeKeys?: Maybe<Array<Maybe<Scalars['Int']>>>;
  state?: Maybe<Scalars['String']>;
  stateRequiredTypeKeys?: Maybe<Array<Maybe<Scalars['Int']>>>;
  typeDescription?: Maybe<Scalars['String']>;
  typeKey?: Maybe<Scalars['Int']>;
  typeListItems?: Maybe<Array<Maybe<SelectListItem>>>;
}

export interface IdentificationModelInput {
  country?: InputMaybe<Scalars['String']>;
  countryListItems?: InputMaybe<Array<InputMaybe<SelectListItemInput>>>;
  countryRequiredTypeKeys?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  expirationRequiredTypeKeys?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  expiry?: InputMaybe<DateModelInput>;
  number?: InputMaybe<Scalars['String']>;
  numberRequiredTypeKeys?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  state?: InputMaybe<Scalars['String']>;
  stateRequiredTypeKeys?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  typeDescription?: InputMaybe<Scalars['String']>;
  typeKey?: InputMaybe<Scalars['Int']>;
  typeListItems?: InputMaybe<Array<InputMaybe<SelectListItemInput>>>;
}

export interface KeyModel {
  exposedKey: Scalars['Int'];
  hiddenKey: Scalars['Int'];
}

export interface KeyModelInput {
  exposedKey?: InputMaybe<Scalars['Int']>;
  hiddenKey?: InputMaybe<Scalars['Int']>;
}

export enum MaritalStatus {
  /** Legally Separated */
  LegallySeparated = 'LEGALLY_SEPARATED',
  /** Married */
  Married = 'MARRIED',
  None = 'NONE',
  /** Unmarried */
  Unmarried = 'UNMARRIED'
}

export interface MessageAuthenticationInputModel {
  lastFour?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['String']>;
}

export interface MessageAuthenticationViewModel {
  redirectUrl?: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
}

export interface MyProfileTabsModel {
  isCustomer: Scalars['Boolean'];
  showSpouseInfo: Scalars['Boolean'];
}

export interface MyProfileTabsModelInput {
  isCustomer?: InputMaybe<Scalars['Boolean']>;
  showSpouseInfo?: InputMaybe<Scalars['Boolean']>;
}

export interface NameModel {
  firstInitial?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  lastInitial?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleInitial?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
}

export interface NameModelInput {
  firstInitial?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  lastInitial?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleInitial?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
}

export enum NoSignatureAction {
  /** None */
  None = 'NONE',
  /** Signature Not Required */
  NotRequiredDoNotRequestSigStored = 'NOT_REQUIRED_DO_NOT_REQUEST_SIG_STORED',
  /** Require more information with docs */
  NotRequiredMoreInfoStored = 'NOT_REQUIRED_MORE_INFO_STORED',
  /** Continue, do not store in Docuware */
  NotRequiredNotStored = 'NOT_REQUIRED_NOT_STORED',
  /** Required, do not display on the web */
  RequiredNoWebDisplayStored = 'REQUIRED_NO_WEB_DISPLAY_STORED',
  /** Document Required */
  RequiredStored = 'REQUIRED_STORED'
}

export interface PendingMilitaryReviewViewModel {
  hasDD214: Scalars['Boolean'];
  isRefinance: Scalars['Boolean'];
}

export enum PreferenceChannel {
  Call = 'CALL',
  Email = 'EMAIL',
  None = 'NONE',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS'
}

export interface PreferenceChannelModel {
  channel: PreferenceChannel;
  isAvailable: Scalars['Boolean'];
  isChangeable: Scalars['Boolean'];
  isPreferred: Scalars['Boolean'];
}

export interface PreferenceChannelModelInput {
  channel?: InputMaybe<PreferenceChannel>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isChangeable?: InputMaybe<Scalars['Boolean']>;
  isPreferred?: InputMaybe<Scalars['Boolean']>;
}

export interface PreferenceModel {
  channels?: Maybe<Array<Maybe<PreferenceChannelModel>>>;
  groupName?: Maybe<Scalars['String']>;
  helpText?: Maybe<Scalars['String']>;
  type: CommunicationType;
}

export interface PreferenceModelInput {
  channels?: InputMaybe<Array<InputMaybe<PreferenceChannelModelInput>>>;
  groupName?: InputMaybe<Scalars['String']>;
  helpText?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CommunicationType>;
}

export interface ResetPasswordResult {
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface RetrievePasswordModel {
  birthDate?: Maybe<DateModel>;
  email?: Maybe<Scalars['String']>;
  findEmailNIN?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  step: RetrievePasswordSteps;
}

export interface RetrievePasswordModelInput {
  birthDate?: InputMaybe<DateModelInput>;
  email?: InputMaybe<Scalars['String']>;
  findEmailNIN?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  step?: InputMaybe<RetrievePasswordSteps>;
}

export enum RetrievePasswordSteps {
  EmailNotFound = 'EMAIL_NOT_FOUND',
  InitialLoad = 'INITIAL_LOAD',
  PasswordResetFailed = 'PASSWORD_RESET_FAILED',
  PasswordResetSent = 'PASSWORD_RESET_SENT'
}

export interface SaveEmailModel {
  confirmEmail?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}

export interface SaveEmailResponseModel {
  saveSuccessful: Scalars['Boolean'];
}

export interface SaveMobilePhoneModel {
  confirmMobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
}

export interface SaveMobilePhoneResponseModel {
  saveSuccessful: Scalars['Boolean'];
}

export interface SelectListGroup {
  disabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
}

export interface SelectListGroupInput {
  disabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface SelectListItem {
  disabled: Scalars['Boolean'];
  group?: Maybe<SelectListGroup>;
  selected: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface SelectListItemInput {
  disabled?: InputMaybe<Scalars['Boolean']>;
  group?: InputMaybe<SelectListGroupInput>;
  selected?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
}

export enum State {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Alberta = 'ALBERTA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  /** British Columbia */
  BritishColumbia = 'BRITISH_COLUMBIA',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  /** District of Columbia */
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Manitoba = 'MANITOBA',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  /** Newfoundland And Labrador */
  NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
  /** New Brunswick */
  NewBrunswick = 'NEW_BRUNSWICK',
  /** New Hampshire */
  NewHampshire = 'NEW_HAMPSHIRE',
  /** New Jersey */
  NewJersey = 'NEW_JERSEY',
  /** New Mexico */
  NewMexico = 'NEW_MEXICO',
  /** New York */
  NewYork = 'NEW_YORK',
  /** Northwest Territories */
  NorthwestTerritories = 'NORTHWEST_TERRITORIES',
  /** North Carolina */
  NorthCarolina = 'NORTH_CAROLINA',
  /** North Dakota */
  NorthDakota = 'NORTH_DAKOTA',
  /** Nova Scotia */
  NovaScotia = 'NOVA_SCOTIA',
  Nunavut = 'NUNAVUT',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Ontario = 'ONTARIO',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  /** Prince Edward Island */
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  /** Rhode Island */
  RhodeIsland = 'RHODE_ISLAND',
  Saskatchewan = 'SASKATCHEWAN',
  /** South Carolina */
  SouthCarolina = 'SOUTH_CAROLINA',
  /** South Dakota */
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Unknown = 'UNKNOWN',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  /** West Virginia */
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
  Yukon = 'YUKON'
}

export interface StringInt32KeyValuePairGraphType {
  key: Scalars['String'];
  value: Scalars['Int'];
}

export interface StringInt32KeyValuePairInputGraphType {
  key: Scalars['String'];
  value: Scalars['Int'];
}

export interface StringStringKeyValuePairGraphType {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface StringStringKeyValuePairInputGraphType {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface TestToolsViewModel {
  cardTestDisabled: Scalars['Boolean'];
  cardTestResult?: Maybe<Scalars['Short']>;
  testResultListItems?: Maybe<Array<Maybe<StringInt32KeyValuePairGraphType>>>;
}

export interface TestToolsViewModelInput {
  cardTestDisabled?: InputMaybe<Scalars['Boolean']>;
  cardTestResult?: InputMaybe<Scalars['Short']>;
  testResultListItems?: InputMaybe<Array<InputMaybe<StringInt32KeyValuePairInputGraphType>>>;
}

export interface UnsubscribeEmailModel {
  toEmail?: Maybe<Scalars['String']>;
}

export interface UnsubscribeInputModel {
  messageId?: InputMaybe<Scalars['String']>;
}

export interface UserMutations {
  accountSetupContinue?: Maybe<AccountSetupModel>;
  accountSetupValidation?: Maybe<AccountSetupModel>;
  cancelEmailVerification?: Maybe<Scalars['String']>;
  cardAuthorizationAuthorize?: Maybe<Scalars['Boolean']>;
  consentToEmailPaymentNotices?: Maybe<ConsentToEmailPaymentNoticesResult>;
  contactUs?: Maybe<ContactUsResponseModel>;
  editAccountSecuritySave?: Maybe<EditAccountSecurityModel>;
  editBankingSaveCard?: Maybe<EditBankingModel>;
  editBankingSaveNewAccount?: Maybe<EditBankingModel>;
  editSpouseSave?: Maybe<EditSpouseModel>;
  findMyEmail?: Maybe<RetrievePasswordModel>;
  giveCommunicationConsent?: Maybe<Scalars['Boolean']>;
  persistAccountDetailsAuthenticationInputs?: Maybe<AccountDetailsAuthenticationViewModel>;
  persistCommunicationConsentsInputs?: Maybe<CommunicationConsentsViewModel>;
  preferencesSave?: Maybe<CommunicationPreferencesModel>;
  preferencesSaveEmail?: Maybe<SaveEmailResponseModel>;
  preferencesSaveMobilePhone?: Maybe<SaveMobilePhoneResponseModel>;
  saveContactInfo?: Maybe<EditContactInfoModel>;
  sendAdAstraEmail?: Maybe<ContactUsResponseModel>;
  sendEmailVerificationCode: Scalars['Boolean'];
  sendMyPassword?: Maybe<RetrievePasswordModel>;
  submitDocuments?: Maybe<DocumentListModel>;
  submitEmailVerificationCode?: Maybe<Scalars['String']>;
  submitResetPassword?: Maybe<ResetPasswordResult>;
  unsubscribe?: Maybe<Scalars['String']>;
}


export interface UserMutationsAccountSetupContinueArgs {
  model?: InputMaybe<AccountSetupModelInput>;
}


export interface UserMutationsAccountSetupValidationArgs {
  model?: InputMaybe<AccountSetupModelInput>;
}


export interface UserMutationsCardAuthorizationAuthorizeArgs {
  authorized?: InputMaybe<Scalars['Boolean']>;
}


export interface UserMutationsContactUsArgs {
  model?: InputMaybe<ContactUsInputModel>;
}


export interface UserMutationsEditAccountSecuritySaveArgs {
  model?: InputMaybe<EditAccountSecurityModelInput>;
}


export interface UserMutationsEditBankingSaveCardArgs {
  model?: InputMaybe<EditBankingModelInput>;
}


export interface UserMutationsEditBankingSaveNewAccountArgs {
  model?: InputMaybe<EditBankingModelInput>;
}


export interface UserMutationsEditSpouseSaveArgs {
  model?: InputMaybe<EditSpouseModelInput>;
}


export interface UserMutationsFindMyEmailArgs {
  model?: InputMaybe<RetrievePasswordModelInput>;
}


export interface UserMutationsGiveCommunicationConsentArgs {
  consentType?: InputMaybe<Scalars['String']>;
}


export interface UserMutationsPersistAccountDetailsAuthenticationInputsArgs {
  inputs?: InputMaybe<AccountDetailsAuthenticationInputModel>;
}


export interface UserMutationsPersistCommunicationConsentsInputsArgs {
  inputs?: InputMaybe<CommunicationConsentsInputModel>;
}


export interface UserMutationsPreferencesSaveArgs {
  messageId?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<CommunicationPreferencesModelInput>;
}


export interface UserMutationsPreferencesSaveEmailArgs {
  model?: InputMaybe<SaveEmailModel>;
}


export interface UserMutationsPreferencesSaveMobilePhoneArgs {
  model?: InputMaybe<SaveMobilePhoneModel>;
}


export interface UserMutationsSaveContactInfoArgs {
  model?: InputMaybe<EditContactInfoModelInput>;
}


export interface UserMutationsSendAdAstraEmailArgs {
  inputs?: InputMaybe<AdAstraContactUsInputModel>;
}


export interface UserMutationsSendEmailVerificationCodeArgs {
  emailAddress?: InputMaybe<Scalars['String']>;
}


export interface UserMutationsSendMyPasswordArgs {
  model?: InputMaybe<RetrievePasswordModelInput>;
}


export interface UserMutationsSubmitDocumentsArgs {
  model?: InputMaybe<DocumentListModelInput>;
}


export interface UserMutationsSubmitEmailVerificationCodeArgs {
  code?: InputMaybe<Scalars['String']>;
}


export interface UserMutationsSubmitResetPasswordArgs {
  token?: InputMaybe<Scalars['String']>;
}


export interface UserMutationsUnsubscribeArgs {
  inputs?: InputMaybe<UnsubscribeInputModel>;
}

export interface UserQueries {
  accountSetup?: Maybe<AccountSetupModel>;
  authenticateMessage?: Maybe<MessageAuthenticationViewModel>;
  cancelEditBanking?: Maybe<EditBankingModel>;
  cancelEditContactInfo?: Maybe<EditContactInfoModel>;
  editAccountSecurityCancel?: Maybe<EditAccountSecurityModel>;
  editAccountSecurityGet?: Maybe<EditAccountSecurityModel>;
  editSpouseCancel?: Maybe<EditSpouseModel>;
  editSpouseGet?: Maybe<EditSpouseModel>;
  getAccessToken?: Maybe<AccessToken>;
  getAccountDetailsAuthenticationView?: Maybe<AccountDetailsAuthenticationViewModel>;
  getAccountSummary?: Maybe<AccountSummaryModel>;
  getAdAstraContactUsViewModel?: Maybe<AdAstraContactUsViewModel>;
  getCardAuthorization?: Maybe<CardAuthorizationModel>;
  getCommunicationConsentsView?: Maybe<CommunicationConsentsViewModel>;
  getCommunicationHistoryView?: Maybe<CommunicationHistoryViewModel>;
  getCommunicationMessages?: Maybe<Array<Maybe<CommunicationMessageModel>>>;
  getConfirmBankingModel?: Maybe<ConfirmBankingModel>;
  getContactInfo?: Maybe<EditContactInfoModel>;
  getContactUs?: Maybe<GetContactUsResponseModel>;
  getDocuments?: Maybe<DocumentListModel>;
  getEditBanking?: Maybe<EditBankingModel>;
  getEmailVerification?: Maybe<EmailVerificationModel>;
  getEmailVerificationCodeDelivery?: Maybe<Scalars['Boolean']>;
  getPendingMilitaryReviewViewModel?: Maybe<PendingMilitaryReviewViewModel>;
  getUnsubscribeEmail?: Maybe<UnsubscribeEmailModel>;
  preferencesGet?: Maybe<CommunicationPreferencesModel>;
  preferencesPaperlessBilling?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<ResetPasswordResult>;
  setDecisionLogicHide?: Maybe<Scalars['Boolean']>;
}


export interface UserQueriesAuthenticateMessageArgs {
  msgAuthInputModel?: InputMaybe<MessageAuthenticationInputModel>;
}


export interface UserQueriesGetCommunicationHistoryViewArgs {
  messageId?: InputMaybe<Scalars['String']>;
}


export interface UserQueriesGetCommunicationMessagesArgs {
  messageKey?: InputMaybe<Scalars['Long']>;
}


export interface UserQueriesGetContactUsArgs {
  emailKey?: InputMaybe<Scalars['Int']>;
}


export interface UserQueriesGetEditBankingArgs {
  creditCardKey?: InputMaybe<KeyModelInput>;
}


export interface UserQueriesGetPendingMilitaryReviewViewModelArgs {
  isRefinance?: InputMaybe<Scalars['Boolean']>;
}


export interface UserQueriesGetUnsubscribeEmailArgs {
  messageId?: InputMaybe<Scalars['String']>;
}


export interface UserQueriesPreferencesGetArgs {
  messageId?: InputMaybe<Scalars['String']>;
}


export interface UserQueriesResetPasswordArgs {
  token?: InputMaybe<Scalars['String']>;
}

export type GiveCommunicationConsentMutationVariables = Exact<{
  consentType?: InputMaybe<Scalars['String']>;
}>;


export type GiveCommunicationConsentMutation = { giveCommunicationConsent?: boolean };

export type GetCommunicationConsentsViewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommunicationConsentsViewQuery = { getCommunicationConsentsView?: { email?: string, hasEmailConsent: boolean, hasSmsConsent: boolean, mobilePhone?: string } };

export type PersistCommunicationConsentsInputsMutationVariables = Exact<{
  inputs: CommunicationConsentsInputModel;
}>;


export type PersistCommunicationConsentsInputsMutation = { persistCommunicationConsentsInputs?: { email?: string, hasEmailConsent: boolean, hasSmsConsent: boolean, mobilePhone?: string } };

export type UnsubscribeMutationVariables = Exact<{
  inputs: UnsubscribeInputModel;
}>;


export type UnsubscribeMutation = { unsubscribe?: string };

export type GetUnsubscribeEmailQueryVariables = Exact<{
  messageId: Scalars['String'];
}>;


export type GetUnsubscribeEmailQuery = { getUnsubscribeEmail?: { toEmail?: string } };

export type GetContactUsQueryVariables = Exact<{
  emailKey: Scalars['Int'];
}>;


export type GetContactUsQuery = { getContactUs?: { body?: string, subject?: string } };

export type GetAdAstraContactUsViewModelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdAstraContactUsViewModelQuery = { getAdAstraContactUsViewModel?: { emailConsentNeeded: boolean, smsConsentNeeded: boolean } };

export type ContactUsMutationVariables = Exact<{
  model?: InputMaybe<ContactUsInputModel>;
}>;


export type ContactUsMutation = { contactUs?: { emailSent: boolean } };

export type SendAdAstraEmailMutationVariables = Exact<{
  inputs: AdAstraContactUsInputModel;
}>;


export type SendAdAstraEmailMutation = { sendAdAstraEmail?: { emailSent: boolean } };

export type GetCommunicationHistoryViewQueryVariables = Exact<{
  messageId?: InputMaybe<Scalars['String']>;
}>;


export type GetCommunicationHistoryViewQuery = { getCommunicationHistoryView?: { redirectUrl?: string, messages?: Array<{ address?: string, attachmentFileNames?: Array<string>, body?: string, dateEntered: Date, formLetterPrintedKey: number, fromAddress?: string, subject?: string, messageKey: number }> } };

export type GetCommunicationMessagesQueryVariables = Exact<{
  messageKey: Scalars['Long'];
}>;


export type GetCommunicationMessagesQuery = { getCommunicationMessages?: Array<{ address?: string, attachmentFileNames?: Array<string>, body?: string, dateEntered: Date, formLetterPrintedKey: number, fromAddress?: string, subject?: string, messageKey: number }> };

export type PreferencesGetQueryVariables = Exact<{
  messageId?: InputMaybe<Scalars['String']>;
}>;


export type PreferencesGetQuery = { preferencesGet?: { accessType: AccessTypes, channels?: Array<PreferenceChannel>, email?: string, messageId?: string, mobilePhone?: string, offerPaperlessBilling: boolean, hasConsentToEmailPaymentNotices: boolean, redirectUrl?: string, needsPhoneConsent: boolean, needsEmailConsent: boolean, phoneListItems?: Array<{ key: string, value: string }>, preferences?: Array<{ groupName?: string, helpText?: string, type: CommunicationType, channels?: Array<{ channel: PreferenceChannel, isAvailable: boolean, isChangeable: boolean, isPreferred: boolean }> }> } };

export type PreferencesPaperlessBillingQueryVariables = Exact<{ [key: string]: never; }>;


export type PreferencesPaperlessBillingQuery = { preferencesPaperlessBilling?: boolean };

export type PreferencesSaveMutationVariables = Exact<{
  model: CommunicationPreferencesModelInput;
  messageId: Scalars['String'];
}>;


export type PreferencesSaveMutation = { preferencesSave?: { accessType: AccessTypes, channels?: Array<PreferenceChannel>, email?: string, messageId?: string, mobilePhone?: string, offerPaperlessBilling: boolean, hasConsentToEmailPaymentNotices: boolean, redirectUrl?: string, phoneListItems?: Array<{ key: string, value: string }>, preferences?: Array<{ groupName?: string, helpText?: string, type: CommunicationType, channels?: Array<{ channel: PreferenceChannel, isAvailable: boolean, isChangeable: boolean, isPreferred: boolean }> }> } };

export type PreferencesSaveEmailMutationVariables = Exact<{
  model?: InputMaybe<SaveEmailModel>;
}>;


export type PreferencesSaveEmailMutation = { preferencesSaveEmail?: { saveSuccessful: boolean } };

export type PreferencesSaveMobilePhoneMutationVariables = Exact<{
  model?: InputMaybe<SaveMobilePhoneModel>;
}>;


export type PreferencesSaveMobilePhoneMutation = { preferencesSaveMobilePhone?: { saveSuccessful: boolean } };

export type CancelEmailVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelEmailVerificationMutation = { cancelEmailVerification?: string };

export type GetEmailVerificationCodeDeliveryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailVerificationCodeDeliveryQuery = { getEmailVerificationCodeDelivery?: boolean };

export type GetEmailVerificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailVerificationQuery = { getEmailVerification?: { codeVerified: boolean, maskedEmail?: string, messageDeliverSuccess?: boolean, messageKeys?: Array<number>, redirect?: string, verificationCode?: string, verificationCodeRecipientEmail?: string, verificationCodeSent: boolean } };

export type SendEmailVerificationCodeMutationVariables = Exact<{
  emailAddress: Scalars['String'];
}>;


export type SendEmailVerificationCodeMutation = { sendEmailVerificationCode: boolean };

export type SubmitEmailVerificationCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type SubmitEmailVerificationCodeMutation = { submitEmailVerificationCode?: string };

export type AccountSetupQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountSetupQuery = { accountSetup?: { email?: string, hidePasswordFields: boolean, isSecurityQuestionRequired: boolean, password?: string, redirectUrl?: string, securityAnswer?: string, securityQuestion?: number, verifyPassword?: string, securityQuestions?: Array<{ key: string, value: number }> } };

export type AccountSetupContinueMutationVariables = Exact<{
  model: AccountSetupModelInput;
}>;


export type AccountSetupContinueMutation = { accountSetupContinue?: { email?: string, hidePasswordFields: boolean, isSecurityQuestionRequired: boolean, password?: string, redirectUrl?: string, securityAnswer?: string, securityQuestion?: number, verifyPassword?: string, securityQuestions?: Array<{ key: string, value: number }> } };

export type AccountSetupValidationMutationVariables = Exact<{
  model: AccountSetupModelInput;
}>;


export type AccountSetupValidationMutation = { accountSetupValidation?: { email?: string, hidePasswordFields: boolean, isSecurityQuestionRequired: boolean, password?: string, redirectUrl?: string, securityAnswer?: string, securityQuestion?: number, verifyPassword?: string, securityQuestions?: Array<{ key: string, value: number }> } };

export type GetAccountSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountSummaryQuery = { getAccountSummary?: { currentState: State, hasInvalidEmail: boolean, isNewLoanEnabled: boolean, offerPaperlessBilling: boolean, redirectUrl?: string } };

export type ConsentToEmailPaymentNoticesMutationVariables = Exact<{ [key: string]: never; }>;


export type ConsentToEmailPaymentNoticesMutation = { consentToEmailPaymentNotices?: { redirectUrl?: string, hasConsent: boolean } };

export type AuthenticateMessageQueryVariables = Exact<{
  msgAuthInputModel?: InputMaybe<MessageAuthenticationInputModel>;
}>;


export type AuthenticateMessageQuery = { authenticateMessage?: { redirectUrl?: string, successful: boolean } };

export type GetAccountDetailsAuthenticationViewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountDetailsAuthenticationViewQuery = { getAccountDetailsAuthenticationView?: { isError: boolean, redirectUrl?: string } };

export type PersistAccountDetailsAuthenticationInputsMutationVariables = Exact<{
  inputs: AccountDetailsAuthenticationInputModel;
}>;


export type PersistAccountDetailsAuthenticationInputsMutation = { persistAccountDetailsAuthenticationInputs?: { isError: boolean, redirectUrl?: string } };

export type GetAccessTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessTokenQuery = { getAccessToken?: { expiresAt?: Date, refreshToken?: string, token?: string, type?: string, grantType?: string } };

export type ResetPasswordQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ResetPasswordQuery = { resetPassword?: { redirectUrl?: string } };

export type SubmitResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SubmitResetPasswordMutation = { submitResetPassword?: { redirectUrl?: string } };

export type SendMyPasswordMutationVariables = Exact<{
  model: RetrievePasswordModelInput;
}>;


export type SendMyPasswordMutation = { sendMyPassword?: { email?: string, findEmailNIN?: string, firstName?: string, lastName?: string, redirectUrl?: string, step: RetrievePasswordSteps, birthDate?: { base?: Date, day?: number, month?: number, year?: number } } };

export type FindMyEmailMutationVariables = Exact<{
  model?: InputMaybe<RetrievePasswordModelInput>;
}>;


export type FindMyEmailMutation = { findMyEmail?: { email?: string, findEmailNIN?: string, firstName?: string, lastName?: string, redirectUrl?: string, step: RetrievePasswordSteps, birthDate?: { base?: Date, day?: number, month?: number, year?: number } } };

export type GetCardAuthorizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardAuthorizationQuery = { getCardAuthorization?: { hideDecline: boolean, isPaymentAuthorizationPromptNeeded: boolean, recordDecline: boolean, showOnPageLoad: boolean, cardsOnFile?: Array<{ bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }> } };

export type CardAuthorzationAuthorizeMutationVariables = Exact<{
  authorized?: InputMaybe<Scalars['Boolean']>;
}>;


export type CardAuthorzationAuthorizeMutation = { cardAuthorizationAuthorize?: boolean };

export type EditAccountSecurityCancelQueryVariables = Exact<{ [key: string]: never; }>;


export type EditAccountSecurityCancelQuery = { editAccountSecurityCancel?: { redirectUrl?: string } };

export type EditAccountSecurityGetQueryVariables = Exact<{ [key: string]: never; }>;


export type EditAccountSecurityGetQuery = { editAccountSecurityGet?: { currentEmail?: string, currentPassword?: string, currentSecurityQuestionText?: string, email?: string, password?: string, redirectUrl?: string, requireCurrentPassword: boolean, securityAnswer?: string, securityQuestion?: number, showEmailVerification: boolean, verifyEmail?: string, verifyPassword?: string, securityQuestionListItems?: Array<{ key: string, value: number }>, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type EditAccountSecuritySaveMutationVariables = Exact<{
  model?: InputMaybe<EditAccountSecurityModelInput>;
}>;


export type EditAccountSecuritySaveMutation = { editAccountSecuritySave?: { currentEmail?: string, currentPassword?: string, currentSecurityQuestionText?: string, email?: string, password?: string, redirectUrl?: string, requireCurrentPassword: boolean, securityAnswer?: string, securityQuestion?: number, showEmailVerification: boolean, verifyEmail?: string, verifyPassword?: string, securityQuestionListItems?: Array<{ key: string, value: number }>, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type CancelEditBankingQueryVariables = Exact<{ [key: string]: never; }>;


export type CancelEditBankingQuery = { cancelEditBanking?: { redirectUrl?: string } };

export type GetDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocumentsQuery = { getDocuments?: { currentState: State, fullNameMatch?: string, isInsuredProduct: boolean, phone?: string, refinanceLoanKey: number, signatures?: Array<string>, subheading?: string, redirectUrl?: string, documents?: Array<{ documentIndex: number, images?: Array<string>, isMarketingConsent: boolean, isMilitaryDoc: boolean, name?: string, noSignatureAction: NoSignatureAction, required: boolean, signatureLabel?: string, showSignatureCheckbox: boolean, visible: boolean }>, name?: { firstName?: string, middleName?: string, lastName?: string, initials?: string }, phoneListItems?: Array<{ value?: string, text?: string }> } };

export type GetEditBankingQueryVariables = Exact<{
  creditCardKey: KeyModelInput;
}>;


export type GetEditBankingQuery = { getEditBanking?: { action?: string, canAddCard: boolean, canChangeBankAccount: boolean, confirmAddCard: boolean, formToShow?: string, isPaymentAuthDocAvailable: boolean, isPaymentAuthorizationNeeded: boolean, paperlessBillingEnabled: boolean, redirectUrl?: string, showLoanPaymentAuthorization: boolean, useHomeAddressForCard: boolean, bankAccount?: { bankAccountNumber?: string, bankAccountNumberVerify?: string, bankInstitutionNumber?: string, bankName?: string, bankTransitNumber?: string }, cardsOnFile?: Array<{ bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, cardNumberToken?: string, cardToken: number, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }>, cardAuthorizationDialog?: { hideDecline: boolean, isPaymentAuthorizationPromptNeeded: boolean, recordDecline: boolean, showOnPageLoad: boolean, cardsOnFile?: Array<{ bin?: string, cardExpiry?: Date, cardNumberToken?: string, cardToken: number, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }> }, editableCard?: { bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, lastFour?: string, key?: { exposedKey: number } }, homeAddress?: { address?: string, city?: string, state?: string, zip?: string, unitType?: string, unitNumber?: string }, newBankAccount?: { bankAccountNumber?: string, bankAccountNumberVerify?: string, bankInstitutionNumber?: string, bankName?: string, bankTransitNumber?: string }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type SetDecisionLogicHideQueryVariables = Exact<{ [key: string]: never; }>;


export type SetDecisionLogicHideQuery = { setDecisionLogicHide?: boolean };

export type EditBankingSaveCardMutationVariables = Exact<{
  model?: InputMaybe<EditBankingModelInput>;
}>;


export type EditBankingSaveCardMutation = { editBankingSaveCard?: { action?: string, canAddCard: boolean, canChangeBankAccount: boolean, confirmAddCard: boolean, formToShow?: string, isPaymentAuthDocAvailable: boolean, isPaymentAuthorizationNeeded: boolean, paperlessBillingEnabled: boolean, redirectUrl?: string, showLoanPaymentAuthorization: boolean, useHomeAddressForCard: boolean, bankAccount?: { bankAccountNumber?: string, bankAccountNumberVerify?: string, bankInstitutionNumber?: string, bankName?: string, bankTransitNumber?: string }, cardAuthorizationDialog?: { hideDecline: boolean, isPaymentAuthorizationPromptNeeded: boolean, recordDecline: boolean, showOnPageLoad: boolean, cardsOnFile?: Array<{ bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, cardNumberToken?: string, cardToken: number, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }> }, cardsOnFile?: Array<{ bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, cardNumberToken?: string, cardToken: number, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }>, editableCard?: { bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, lastFour?: string, key?: { exposedKey: number } }, homeAddress?: { address?: string, city?: string, state?: string, zip?: string, unitType?: string, unitNumber?: string }, newBankAccount?: { bankAccountNumber?: string, bankAccountNumberVerify?: string, bankInstitutionNumber?: string, bankName?: string, bankTransitNumber?: string }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type EditBankingSaveNewAccountMutationVariables = Exact<{
  model?: InputMaybe<EditBankingModelInput>;
}>;


export type EditBankingSaveNewAccountMutation = { editBankingSaveNewAccount?: { action?: string, canAddCard: boolean, canChangeBankAccount: boolean, confirmAddCard: boolean, formToShow?: string, isPaymentAuthDocAvailable: boolean, isPaymentAuthorizationNeeded: boolean, paperlessBillingEnabled: boolean, redirectUrl?: string, showLoanPaymentAuthorization: boolean, useHomeAddressForCard: boolean, bankAccount?: { bankAccountNumber?: string, bankAccountNumberVerify?: string, bankInstitutionNumber?: string, bankName?: string, bankTransitNumber?: string }, cardAuthorizationDialog?: { hideDecline: boolean, isPaymentAuthorizationPromptNeeded: boolean, recordDecline: boolean, showOnPageLoad: boolean, cardsOnFile?: Array<{ bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, cardNumberToken?: string, cardToken: number, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }> }, cardsOnFile?: Array<{ bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, cardNumberToken?: string, cardToken: number, lastFour?: string, brand: CreditCardBrand, key?: { exposedKey: number } }>, editableCard?: { bin?: string, bridgePayPermanentToken?: string, cardExpiry?: Date, lastFour?: string, key?: { exposedKey: number } }, homeAddress?: { address?: string, city?: string, state?: string, zip?: string, unitType?: string, unitNumber?: string }, newBankAccount?: { bankAccountNumber?: string, bankAccountNumberVerify?: string, bankInstitutionNumber?: string, bankName?: string, bankTransitNumber?: string }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type SubmitDocumentsMutationVariables = Exact<{
  model: DocumentListModelInput;
}>;


export type SubmitDocumentsMutation = { submitDocuments?: { currentState: State, fullNameMatch?: string, isInsuredProduct: boolean, phone?: string, refinanceLoanKey: number, signatures?: Array<string>, subheading?: string, redirectUrl?: string, documents?: Array<{ documentIndex: number, images?: Array<string>, isMarketingConsent: boolean, isMilitaryDoc: boolean, name?: string, noSignatureAction: NoSignatureAction, required: boolean, signatureLabel?: string, showSignatureCheckbox: boolean, visible: boolean }>, name?: { firstName?: string, middleName?: string, lastName?: string, initials?: string }, phoneListItems?: Array<{ value?: string, text?: string }> } };

export type GetConfirmBankingModelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfirmBankingModelQuery = { getConfirmBankingModel?: { bankName?: string, redirectUrl?: string, showDecisionLogic: boolean, showEmailUs: boolean, showYodlee: boolean } };

export type CancelEditContactInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CancelEditContactInfoQuery = { cancelEditContactInfo?: { redirectUrl?: string } };

export type GetContactInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactInfoQuery = { getContactInfo?: { cellPhone?: string, email?: string, firstName?: string, fullName?: string, homePhone?: string, lastName?: string, middleInitial?: string, nIN?: string, redirectUrl?: string, communicationConsentPromotional: boolean, communicationConsentOperational: boolean, address?: { address?: string, city?: string, state?: string, unitNumber?: string, unitType?: string, zip?: string }, birthDate?: { base?: Date, day?: number, month?: number, year?: number }, identification?: { number?: string, state?: string, typeKey?: number, typeListItems?: Array<{ text?: string, value?: string }> }, residenceSince?: { base?: Date, day?: number, month?: number, year?: number }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type SaveContactInfoMutationVariables = Exact<{
  model?: InputMaybe<EditContactInfoModelInput>;
}>;


export type SaveContactInfoMutation = { saveContactInfo?: { cellPhone?: string, email?: string, firstName?: string, fullName?: string, homePhone?: string, lastName?: string, middleInitial?: string, nIN?: string, redirectUrl?: string, communicationConsentPromotional: boolean, communicationConsentOperational: boolean, address?: { address?: string, city?: string, state?: string, unitNumber?: string, unitType?: string, zip?: string }, birthDate?: { base?: Date, day?: number, month?: number, year?: number }, identification?: { number?: string, state?: string, typeKey?: number, typeListItems?: Array<{ text?: string, value?: string }> }, residenceSince?: { base?: Date, day?: number, month?: number, year?: number }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean } } };

export type EditSpouseCancelQueryVariables = Exact<{ [key: string]: never; }>;


export type EditSpouseCancelQuery = { editSpouseCancel?: { redirectUrl?: string } };

export type EditSpouseGetQueryVariables = Exact<{ [key: string]: never; }>;


export type EditSpouseGetQuery = { editSpouseGet?: { firstName?: string, lastName?: string, isSameAddressAsSpouse: boolean, maritalStatus: MaritalStatus, redirectUrl?: string, address?: { address?: string, city?: string, state?: string, unitNumber?: string, unitType?: string, zip?: string }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean }, primaryAddress?: { address?: string, city?: string, state?: string, unitNumber?: string, unitType?: string, zip?: string } } };

export type EditSpouseSaveMutationVariables = Exact<{
  model?: InputMaybe<EditSpouseModelInput>;
}>;


export type EditSpouseSaveMutation = { editSpouseSave?: { firstName?: string, lastName?: string, maritalStatus: MaritalStatus, redirectUrl?: string, address?: { address?: string, city?: string, state?: string, unitNumber?: string, unitType?: string, zip?: string }, tabs?: { isCustomer: boolean, showSpouseInfo: boolean }, primaryAddress?: { address?: string, city?: string, state?: string, unitNumber?: string, unitType?: string, zip?: string } } };

export type GetPendingMilitaryReviewViewModelQueryVariables = Exact<{
  isRefinance?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetPendingMilitaryReviewViewModelQuery = { getPendingMilitaryReviewViewModel?: { hasDD214: boolean, isRefinance: boolean } };


export class UserClient extends BaseClient {
  path = '/graphql/User';
  

      public giveCommunicationConsent(consentType: GiveCommunicationConsentMutationVariables['consentType'], signal?: AbortSignal | RequestInit) {
          return this.request<GiveCommunicationConsentMutation>(`mutation GiveCommunicationConsent($consentType: String) {
    giveCommunicationConsent(consentType: $consentType)
}`, { consentType }, createRequestInit(signal), 'GiveCommunicationConsent');
      }
  

      public persistCommunicationConsentsInputs(inputs: PersistCommunicationConsentsInputsMutationVariables['inputs'], signal?: AbortSignal | RequestInit) {
          return this.request<PersistCommunicationConsentsInputsMutation>(`mutation PersistCommunicationConsentsInputs($inputs: CommunicationConsentsInputModel!) {
    persistCommunicationConsentsInputs(inputs: $inputs) {
        email
        hasEmailConsent
        hasSmsConsent
        mobilePhone
    }
}`, { inputs }, createRequestInit(signal), 'PersistCommunicationConsentsInputs');
      }
  

      public unsubscribe(inputs: UnsubscribeMutationVariables['inputs'], signal?: AbortSignal | RequestInit) {
          return this.request<UnsubscribeMutation>(`mutation Unsubscribe($inputs: UnsubscribeInputModel!) {
    unsubscribe(inputs: $inputs)
}`, { inputs }, createRequestInit(signal), 'Unsubscribe');
      }
  

      public getCommunicationConsentsView(signal?: AbortSignal | RequestInit) {
          return this.request<GetCommunicationConsentsViewQuery>(`query GetCommunicationConsentsView {
    getCommunicationConsentsView {
        email
        hasEmailConsent
        hasSmsConsent
        mobilePhone
    }
}`, {}, createRequestInit(signal), 'GetCommunicationConsentsView'); 
      }
  

      public getUnsubscribeEmail(messageId: GetUnsubscribeEmailQueryVariables['messageId'], signal?: AbortSignal | RequestInit) {
          return this.request<GetUnsubscribeEmailQuery>(`query GetUnsubscribeEmail($messageId: String!) {
    getUnsubscribeEmail(messageId: $messageId) {
        toEmail
    }
}`, { messageId }, createRequestInit(signal), 'GetUnsubscribeEmail'); 
      }
  

      public contactUs(model: ContactUsMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<ContactUsMutation>(`mutation ContactUs($model: ContactUsInputModel) {
    contactUs(model: $model) {
        emailSent
    }
}`, { model }, createRequestInit(signal), 'ContactUs');
      }
  

      public sendAdAstraEmail(inputs: SendAdAstraEmailMutationVariables['inputs'], signal?: AbortSignal | RequestInit) {
          return this.request<SendAdAstraEmailMutation>(`mutation SendAdAstraEmail($inputs: AdAstraContactUsInputModel!) {
    sendAdAstraEmail(inputs: $inputs) {
        emailSent
    }
}`, { inputs }, createRequestInit(signal), 'SendAdAstraEmail');
      }
  

      public getContactUs(emailKey: GetContactUsQueryVariables['emailKey'], signal?: AbortSignal | RequestInit) {
          return this.request<GetContactUsQuery>(`query GetContactUs($emailKey: Int!) {
    getContactUs(emailKey: $emailKey) {
        body
        subject
    }
}`, { emailKey }, createRequestInit(signal), 'GetContactUs'); 
      }
  

      public getAdAstraContactUsViewModel(signal?: AbortSignal | RequestInit) {
          return this.request<GetAdAstraContactUsViewModelQuery>(`query GetAdAstraContactUsViewModel {
    getAdAstraContactUsViewModel {
        emailConsentNeeded
        smsConsentNeeded
    }
}`, {}, createRequestInit(signal), 'GetAdAstraContactUsViewModel'); 
      }
  

      public getCommunicationHistoryView(messageId: GetCommunicationHistoryViewQueryVariables['messageId'], signal?: AbortSignal | RequestInit) {
          return this.request<GetCommunicationHistoryViewQuery>(`query GetCommunicationHistoryView($messageId: String) {
    getCommunicationHistoryView(messageId: $messageId) {
        redirectUrl
        messages {
            address
            attachmentFileNames
            body
            dateEntered
            formLetterPrintedKey
            fromAddress
            subject
            messageKey
        }
    }
}`, { messageId }, createRequestInit(signal), 'GetCommunicationHistoryView'); 
      }
  

      public getCommunicationMessages(messageKey: GetCommunicationMessagesQueryVariables['messageKey'], signal?: AbortSignal | RequestInit) {
          return this.request<GetCommunicationMessagesQuery>(`query GetCommunicationMessages($messageKey: Long!) {
    getCommunicationMessages(messageKey: $messageKey) {
        address
        attachmentFileNames
        body
        dateEntered
        formLetterPrintedKey
        fromAddress
        subject
        messageKey
    }
}`, { messageKey }, createRequestInit(signal), 'GetCommunicationMessages'); 
      }
  

      public preferencesSave(model: PreferencesSaveMutationVariables['model'], messageId: PreferencesSaveMutationVariables['messageId'], signal?: AbortSignal | RequestInit) {
          return this.request<PreferencesSaveMutation>(`mutation PreferencesSave($model: CommunicationPreferencesModelInput!, $messageId: String!) {
    preferencesSave(model: $model, messageId: $messageId) {
        accessType
        channels
        email
        messageId
        mobilePhone
        offerPaperlessBilling
        hasConsentToEmailPaymentNotices
        phoneListItems {
            key
            value
        }
        preferences {
            channels {
                channel
                isAvailable
                isChangeable
                isPreferred
            }
            groupName
            helpText
            type
        }
        redirectUrl
    }
}`, { model,messageId }, createRequestInit(signal), 'PreferencesSave');
      }
  

      public preferencesSaveEmail(model: PreferencesSaveEmailMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<PreferencesSaveEmailMutation>(`mutation PreferencesSaveEmail($model: SaveEmailModel) {
    preferencesSaveEmail(model: $model) {
        saveSuccessful
    }
}`, { model }, createRequestInit(signal), 'PreferencesSaveEmail');
      }
  

      public preferencesSaveMobilePhone(model: PreferencesSaveMobilePhoneMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<PreferencesSaveMobilePhoneMutation>(`mutation PreferencesSaveMobilePhone($model: SaveMobilePhoneModel) {
    preferencesSaveMobilePhone(model: $model) {
        saveSuccessful
    }
}`, { model }, createRequestInit(signal), 'PreferencesSaveMobilePhone');
      }
  

      public preferencesGet(messageId: PreferencesGetQueryVariables['messageId'], signal?: AbortSignal | RequestInit) {
          return this.request<PreferencesGetQuery>(`query PreferencesGet($messageId: String) {
    preferencesGet(messageId: $messageId) {
        accessType
        channels
        email
        messageId
        mobilePhone
        offerPaperlessBilling
        hasConsentToEmailPaymentNotices
        phoneListItems {
            key
            value
        }
        preferences {
            channels {
                channel
                isAvailable
                isChangeable
                isPreferred
            }
            groupName
            helpText
            type
        }
        redirectUrl
        needsPhoneConsent
        needsEmailConsent
    }
}`, { messageId }, createRequestInit(signal), 'PreferencesGet'); 
      }
  

      public preferencesPaperlessBilling(signal?: AbortSignal | RequestInit) {
          return this.request<PreferencesPaperlessBillingQuery>(`query PreferencesPaperlessBilling {
    preferencesPaperlessBilling
}`, {}, createRequestInit(signal), 'PreferencesPaperlessBilling'); 
      }
  

      public cancelEmailVerification(signal?: AbortSignal | RequestInit) {
          return this.request<CancelEmailVerificationMutation>(`mutation CancelEmailVerification {
    cancelEmailVerification
}`, {}, createRequestInit(signal), 'CancelEmailVerification');
      }
  

      public sendEmailVerificationCode(emailAddress: SendEmailVerificationCodeMutationVariables['emailAddress'], signal?: AbortSignal | RequestInit) {
          return this.request<SendEmailVerificationCodeMutation>(`mutation SendEmailVerificationCode($emailAddress: String!) {
    sendEmailVerificationCode(emailAddress: $emailAddress)
}`, { emailAddress }, createRequestInit(signal), 'SendEmailVerificationCode');
      }
  

      public submitEmailVerificationCode(code: SubmitEmailVerificationCodeMutationVariables['code'], signal?: AbortSignal | RequestInit) {
          return this.request<SubmitEmailVerificationCodeMutation>(`mutation SubmitEmailVerificationCode($code: String!) {
    submitEmailVerificationCode(code: $code)
}`, { code }, createRequestInit(signal), 'SubmitEmailVerificationCode');
      }
  

      public getEmailVerificationCodeDelivery(signal?: AbortSignal | RequestInit) {
          return this.request<GetEmailVerificationCodeDeliveryQuery>(`query GetEmailVerificationCodeDelivery {
    getEmailVerificationCodeDelivery
}`, {}, createRequestInit(signal), 'GetEmailVerificationCodeDelivery'); 
      }
  

      public getEmailVerification(signal?: AbortSignal | RequestInit) {
          return this.request<GetEmailVerificationQuery>(`query GetEmailVerification {
    getEmailVerification {
        codeVerified
        maskedEmail
        messageDeliverSuccess
        messageKeys
        redirect
        verificationCode
        verificationCodeRecipientEmail
        verificationCodeSent
    }
}`, {}, createRequestInit(signal), 'GetEmailVerification'); 
      }
  

      public accountSetupContinue(model: AccountSetupContinueMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<AccountSetupContinueMutation>(`mutation AccountSetupContinue($model: AccountSetupModelInput!) {
    accountSetupContinue(model: $model) {
        email
        hidePasswordFields
        isSecurityQuestionRequired
        password
        redirectUrl
        securityAnswer
        securityQuestion
        securityQuestions {
            key
            value
        }
        verifyPassword
    }
}`, { model }, createRequestInit(signal), 'AccountSetupContinue');
      }
  

      public accountSetupValidation(model: AccountSetupValidationMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<AccountSetupValidationMutation>(`mutation AccountSetupValidation($model: AccountSetupModelInput!) {
    accountSetupValidation(model: $model) {
        email
        hidePasswordFields
        isSecurityQuestionRequired
        password
        redirectUrl
        securityAnswer
        securityQuestion
        securityQuestions {
            key
            value
        }
        verifyPassword
    }
}`, { model }, createRequestInit(signal), 'AccountSetupValidation');
      }
  

      public accountSetup(signal?: AbortSignal | RequestInit) {
          return this.request<AccountSetupQuery>(`query AccountSetup {
    accountSetup {
        email
        hidePasswordFields
        isSecurityQuestionRequired
        password
        redirectUrl
        securityAnswer
        securityQuestion
        securityQuestions {
            key
            value
        }
        verifyPassword
    }
}`, {}, createRequestInit(signal), 'AccountSetup'); 
      }
  

      public consentToEmailPaymentNotices(signal?: AbortSignal | RequestInit) {
          return this.request<ConsentToEmailPaymentNoticesMutation>(`mutation ConsentToEmailPaymentNotices {
    consentToEmailPaymentNotices {
        redirectUrl
        hasConsent
    }
}`, {}, createRequestInit(signal), 'ConsentToEmailPaymentNotices');
      }
  

      public getAccountSummary(signal?: AbortSignal | RequestInit) {
          return this.request<GetAccountSummaryQuery>(`query GetAccountSummary {
    getAccountSummary {
        currentState
        hasInvalidEmail
        isNewLoanEnabled
        offerPaperlessBilling
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'GetAccountSummary'); 
      }
  

      public persistAccountDetailsAuthenticationInputs(inputs: PersistAccountDetailsAuthenticationInputsMutationVariables['inputs'], signal?: AbortSignal | RequestInit) {
          return this.request<PersistAccountDetailsAuthenticationInputsMutation>(`mutation PersistAccountDetailsAuthenticationInputs($inputs: AccountDetailsAuthenticationInputModel!) {
    persistAccountDetailsAuthenticationInputs(inputs: $inputs) {
        isError
        redirectUrl
    }
}`, { inputs }, createRequestInit(signal), 'PersistAccountDetailsAuthenticationInputs');
      }
  

      public authenticateMessage(msgAuthInputModel: AuthenticateMessageQueryVariables['msgAuthInputModel'], signal?: AbortSignal | RequestInit) {
          return this.request<AuthenticateMessageQuery>(`query AuthenticateMessage($msgAuthInputModel: MessageAuthenticationInputModel) {
    authenticateMessage(msgAuthInputModel: $msgAuthInputModel) {
        redirectUrl
        successful
    }
}`, { msgAuthInputModel }, createRequestInit(signal), 'AuthenticateMessage'); 
      }
  

      public getAccountDetailsAuthenticationView(signal?: AbortSignal | RequestInit) {
          return this.request<GetAccountDetailsAuthenticationViewQuery>(`query GetAccountDetailsAuthenticationView {
    getAccountDetailsAuthenticationView {
        isError
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'GetAccountDetailsAuthenticationView'); 
      }
  

      public getAccessToken(signal?: AbortSignal | RequestInit) {
          return this.request<GetAccessTokenQuery>(`query GetAccessToken {
    getAccessToken {
        expiresAt
        refreshToken
        token
        type
        grantType
    }
}`, {}, createRequestInit(signal), 'GetAccessToken'); 
      }
  

      public submitResetPassword(token: SubmitResetPasswordMutationVariables['token'], signal?: AbortSignal | RequestInit) {
          return this.request<SubmitResetPasswordMutation>(`mutation SubmitResetPassword($token: String!) {
    submitResetPassword(token: $token) {
        redirectUrl
    }
}`, { token }, createRequestInit(signal), 'SubmitResetPassword');
      }
  

      public resetPassword(token: ResetPasswordQueryVariables['token'], signal?: AbortSignal | RequestInit) {
          return this.request<ResetPasswordQuery>(`query ResetPassword($token: String!) {
    resetPassword(token: $token) {
        redirectUrl
    }
}`, { token }, createRequestInit(signal), 'ResetPassword'); 
      }
  

      public sendMyPassword(model: SendMyPasswordMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<SendMyPasswordMutation>(`mutation SendMyPassword($model: RetrievePasswordModelInput!) {
    sendMyPassword(model: $model) {
        birthDate {
            base
            day
            month
            year
        }
        email
        findEmailNIN
        firstName
        lastName
        redirectUrl
        step
    }
}`, { model }, createRequestInit(signal), 'SendMyPassword');
      }
  

      public findMyEmail(model: FindMyEmailMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<FindMyEmailMutation>(`mutation FindMyEmail($model: RetrievePasswordModelInput) {
    findMyEmail(model: $model) {
        birthDate {
            base
            day
            month
            year
        }
        email
        findEmailNIN
        firstName
        lastName
        redirectUrl
        step
    }
}`, { model }, createRequestInit(signal), 'FindMyEmail');
      }
  

      public cardAuthorzationAuthorize(authorized: CardAuthorzationAuthorizeMutationVariables['authorized'], signal?: AbortSignal | RequestInit) {
          return this.request<CardAuthorzationAuthorizeMutation>(`mutation CardAuthorzationAuthorize($authorized: Boolean) {
    cardAuthorizationAuthorize(authorized: $authorized)
}`, { authorized }, createRequestInit(signal), 'CardAuthorzationAuthorize');
      }
  

      public getCardAuthorization(signal?: AbortSignal | RequestInit) {
          return this.request<GetCardAuthorizationQuery>(`query GetCardAuthorization {
    getCardAuthorization{
        cardsOnFile {
            bin
            bridgePayPermanentToken
            cardExpiry
            lastFour
            key {
                exposedKey
            }
            brand
        }
        hideDecline
        isPaymentAuthorizationPromptNeeded
        recordDecline
        showOnPageLoad
    }
}`, {}, createRequestInit(signal), 'GetCardAuthorization'); 
      }
  

      public editAccountSecuritySave(model: EditAccountSecuritySaveMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<EditAccountSecuritySaveMutation>(`mutation EditAccountSecuritySave($model: EditAccountSecurityModelInput) {
    editAccountSecuritySave(model: $model) {
        currentEmail
        currentPassword
        currentSecurityQuestionText
        email
        password
        redirectUrl
        requireCurrentPassword
        securityAnswer
        securityQuestion
        securityQuestionListItems {
            key
            value
        }
        showEmailVerification
        tabs {
            isCustomer
            showSpouseInfo
        }
        verifyEmail
        verifyPassword
    }
}`, { model }, createRequestInit(signal), 'EditAccountSecuritySave');
      }
  

      public editAccountSecurityCancel(signal?: AbortSignal | RequestInit) {
          return this.request<EditAccountSecurityCancelQuery>(`query EditAccountSecurityCancel {
    editAccountSecurityCancel {
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'EditAccountSecurityCancel'); 
      }
  

      public editAccountSecurityGet(signal?: AbortSignal | RequestInit) {
          return this.request<EditAccountSecurityGetQuery>(`query EditAccountSecurityGet {
    editAccountSecurityGet {
        currentEmail
        currentPassword
        currentSecurityQuestionText
        email
        password
        redirectUrl
        requireCurrentPassword
        securityAnswer
        securityQuestion
        securityQuestionListItems {
            key
            value
        }
        showEmailVerification
        tabs {
            isCustomer
            showSpouseInfo
        }
        verifyEmail
        verifyPassword
    }
}`, {}, createRequestInit(signal), 'EditAccountSecurityGet'); 
      }
  

      public editBankingSaveCard(model: EditBankingSaveCardMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<EditBankingSaveCardMutation>(`mutation EditBankingSaveCard($model: EditBankingModelInput) {
    editBankingSaveCard(model: $model) {
        action
        bankAccount {
            bankAccountNumber
            bankAccountNumberVerify
            bankInstitutionNumber
            bankName
            bankTransitNumber
        }
        canAddCard
        canChangeBankAccount
        cardAuthorizationDialog {
            cardsOnFile {
                bin
                bridgePayPermanentToken
                cardExpiry
                cardNumberToken
                cardToken
                lastFour
                key {
                    exposedKey
                }
                brand
            }
            hideDecline
            isPaymentAuthorizationPromptNeeded
            recordDecline
            showOnPageLoad
        }
        cardsOnFile {
            bin
            bridgePayPermanentToken
            cardExpiry
            cardNumberToken
            cardToken
            lastFour
            key {
                exposedKey
            }
            brand
        }
        confirmAddCard
        editableCard {
            bin
            bridgePayPermanentToken
            cardExpiry
            lastFour
            key {
                exposedKey
            }
        }
        formToShow
        homeAddress {
            address
            city
            state
            zip
            unitType
            unitNumber
        }
        isPaymentAuthDocAvailable
        isPaymentAuthorizationNeeded
        newBankAccount {
            bankAccountNumber
            bankAccountNumberVerify
            bankInstitutionNumber
            bankName
            bankTransitNumber
        }
        paperlessBillingEnabled
        redirectUrl
        showLoanPaymentAuthorization
        tabs {
            isCustomer
            showSpouseInfo
        }
        useHomeAddressForCard
    }
}`, { model }, createRequestInit(signal), 'EditBankingSaveCard');
      }
  

      public editBankingSaveNewAccount(model: EditBankingSaveNewAccountMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<EditBankingSaveNewAccountMutation>(`mutation EditBankingSaveNewAccount($model: EditBankingModelInput) {
    editBankingSaveNewAccount(model: $model) {
        action
        bankAccount {
            bankAccountNumber
            bankAccountNumberVerify
            bankInstitutionNumber
            bankName
            bankTransitNumber
        }
        canAddCard
        canChangeBankAccount
        cardAuthorizationDialog {
            cardsOnFile {
                bin
                bridgePayPermanentToken
                cardExpiry
                cardNumberToken
                cardToken
                lastFour
                key {
                    exposedKey
                }
                brand
            }
            hideDecline
            isPaymentAuthorizationPromptNeeded
            recordDecline
            showOnPageLoad
        }
        cardsOnFile {
            bin
            bridgePayPermanentToken
            cardExpiry
            cardNumberToken
            cardToken
            lastFour
            key {
                exposedKey
            }
            brand
        }
        confirmAddCard
        editableCard {
            bin
            bridgePayPermanentToken
            cardExpiry
            lastFour
            key {
                exposedKey
            }
        }
        formToShow
        homeAddress {
            address
            city
            state
            zip
            unitType
            unitNumber
        }
        isPaymentAuthDocAvailable
        isPaymentAuthorizationNeeded
        newBankAccount {
            bankAccountNumber
            bankAccountNumberVerify
            bankInstitutionNumber
            bankName
            bankTransitNumber
        }
        paperlessBillingEnabled
        redirectUrl
        showLoanPaymentAuthorization
        tabs {
            isCustomer
            showSpouseInfo
        }
        useHomeAddressForCard
    }
}`, { model }, createRequestInit(signal), 'EditBankingSaveNewAccount');
      }
  

      public submitDocuments(model: SubmitDocumentsMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<SubmitDocumentsMutation>(`mutation SubmitDocuments($model: DocumentListModelInput!) {
    submitDocuments(model: $model) {
        currentState
        documents {
            documentIndex
            images
            isMarketingConsent
            isMilitaryDoc
            name
            noSignatureAction
            required
            signatureLabel
            showSignatureCheckbox
            visible
        }
        fullNameMatch
        isInsuredProduct
        name {
            firstName
            middleName
            lastName
            initials
        }
        phone
        phoneListItems {
            value
            text
        }
        refinanceLoanKey
        signatures
        subheading
        redirectUrl
    }
}`, { model }, createRequestInit(signal), 'SubmitDocuments');
      }
  

      public cancelEditBanking(signal?: AbortSignal | RequestInit) {
          return this.request<CancelEditBankingQuery>(`query CancelEditBanking {
    cancelEditBanking {
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'CancelEditBanking'); 
      }
  

      public getDocuments(signal?: AbortSignal | RequestInit) {
          return this.request<GetDocumentsQuery>(`query GetDocuments {
    getDocuments {
        currentState
        documents {
            documentIndex
            images
            isMarketingConsent
            isMilitaryDoc
            name
            noSignatureAction
            required
            signatureLabel
            showSignatureCheckbox
            visible
        }
        fullNameMatch
        isInsuredProduct
        name {
            firstName
            middleName
            lastName
            initials
        }
        phone
        phoneListItems {
            value
            text
        }
        refinanceLoanKey
        signatures
        subheading
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'GetDocuments'); 
      }
  

      public getEditBanking(creditCardKey: GetEditBankingQueryVariables['creditCardKey'], signal?: AbortSignal | RequestInit) {
          return this.request<GetEditBankingQuery>(`query GetEditBanking($creditCardKey: KeyModelInput!) {
    getEditBanking(creditCardKey: $creditCardKey) {
        action
        bankAccount {
            bankAccountNumber
            bankAccountNumberVerify
            bankInstitutionNumber
            bankName
            bankTransitNumber
        }
        canAddCard
        canChangeBankAccount
        cardsOnFile {
            bin
            bridgePayPermanentToken
            cardExpiry
            cardNumberToken
            cardToken
            lastFour
            key {
                exposedKey
            }
            brand
        }
        cardAuthorizationDialog {
            cardsOnFile {
                bin
                cardExpiry
                cardNumberToken
                cardToken
                lastFour
                key {
                    exposedKey
                }
                brand
            }
            hideDecline
            isPaymentAuthorizationPromptNeeded
            recordDecline
            showOnPageLoad
        }
        confirmAddCard
        editableCard {
            bin
            bridgePayPermanentToken
            cardExpiry
            lastFour
            key {
                exposedKey
            }
        }
        formToShow
        homeAddress {
            address
            city
            state
            zip
            unitType
            unitNumber
        }
        isPaymentAuthDocAvailable
        isPaymentAuthorizationNeeded
        newBankAccount {
            bankAccountNumber
            bankAccountNumberVerify
            bankInstitutionNumber
            bankName
            bankTransitNumber
        }
        paperlessBillingEnabled
        redirectUrl
        showLoanPaymentAuthorization
        tabs {
            isCustomer
            showSpouseInfo
        }
        useHomeAddressForCard
    }
}`, { creditCardKey }, createRequestInit(signal), 'GetEditBanking'); 
      }
  

      public setDecisionLogicHide(signal?: AbortSignal | RequestInit) {
          return this.request<SetDecisionLogicHideQuery>(`query SetDecisionLogicHide {
    setDecisionLogicHide
}`, {}, createRequestInit(signal), 'SetDecisionLogicHide'); 
      }
  

      public getConfirmBankingModel(signal?: AbortSignal | RequestInit) {
          return this.request<GetConfirmBankingModelQuery>(`query GetConfirmBankingModel {
    getConfirmBankingModel {
        bankName
        redirectUrl
        showDecisionLogic
        showEmailUs
        showYodlee
    }
}`, {}, createRequestInit(signal), 'GetConfirmBankingModel'); 
      }
  

      public saveContactInfo(model: SaveContactInfoMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<SaveContactInfoMutation>(`mutation SaveContactInfo($model: EditContactInfoModelInput) {
    saveContactInfo(model: $model) {
        address {
            address
            city
            state
            unitNumber
            unitType
            zip
        }
        birthDate {
            base
            day
            month
            year
        }
        cellPhone
        email
        firstName
        fullName
        homePhone
        identification {
            number
            state
            typeKey
            typeListItems {
                text
                value
            }
        }
        lastName
        middleInitial
        nIN
        residenceSince {
            base
            day
            month
            year
        }
        redirectUrl
        communicationConsentPromotional
        communicationConsentOperational
        tabs {
            isCustomer
            showSpouseInfo
        }
    }
}`, { model }, createRequestInit(signal), 'SaveContactInfo');
      }
  

      public cancelEditContactInfo(signal?: AbortSignal | RequestInit) {
          return this.request<CancelEditContactInfoQuery>(`query CancelEditContactInfo {
    cancelEditContactInfo {
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'CancelEditContactInfo'); 
      }
  

      public getContactInfo(signal?: AbortSignal | RequestInit) {
          return this.request<GetContactInfoQuery>(`query GetContactInfo {
    getContactInfo {
        address {
            address
            city
            state
            unitNumber
            unitType
            zip
        }
        birthDate {
            base
            day
            month
            year
        }
        cellPhone
        email
        firstName
        fullName
        homePhone
        identification {
            number
            state
            typeKey
            typeListItems {
                text
                value
            }
        }
        lastName
        middleInitial
        nIN
        residenceSince {
            base
            day
            month
            year
        }
        redirectUrl
        communicationConsentPromotional
        communicationConsentOperational
        tabs {
            isCustomer
            showSpouseInfo
        }
    }
}`, {}, createRequestInit(signal), 'GetContactInfo'); 
      }
  

      public editSpouseSave(model: EditSpouseSaveMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<EditSpouseSaveMutation>(`mutation EditSpouseSave($model: EditSpouseModelInput) {
    editSpouseSave(model: $model) {
        address {
            address
            city
            state
            unitNumber
            unitType
            zip
        }
        firstName
        lastName
        maritalStatus
        tabs {
            isCustomer
            showSpouseInfo
        }
        primaryAddress {
            address
            city
            state
            unitNumber
            unitType
            zip
        }
        redirectUrl
    }
}`, { model }, createRequestInit(signal), 'EditSpouseSave');
      }
  

      public editSpouseCancel(signal?: AbortSignal | RequestInit) {
          return this.request<EditSpouseCancelQuery>(`query EditSpouseCancel {
    editSpouseCancel {
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'EditSpouseCancel'); 
      }
  

      public editSpouseGet(signal?: AbortSignal | RequestInit) {
          return this.request<EditSpouseGetQuery>(`query EditSpouseGet {
    editSpouseGet {
        address {
            address
            city
            state
            unitNumber
            unitType
            zip
        }
        firstName
        lastName
        isSameAddressAsSpouse
        maritalStatus
        tabs {
            isCustomer
            showSpouseInfo
        }
        primaryAddress {
            address
            city
            state
            unitNumber
            unitType
            zip
        }
        redirectUrl
    }
}`, {}, createRequestInit(signal), 'EditSpouseGet'); 
      }
  

      public getPendingMilitaryReviewViewModel(isRefinance: GetPendingMilitaryReviewViewModelQueryVariables['isRefinance'], signal?: AbortSignal | RequestInit) {
          return this.request<GetPendingMilitaryReviewViewModelQuery>(`query GetPendingMilitaryReviewViewModel($isRefinance: Boolean){
    getPendingMilitaryReviewViewModel(isRefinance: $isRefinance){
        hasDD214
        isRefinance
    }
}`, { isRefinance }, createRequestInit(signal), 'GetPendingMilitaryReviewViewModel'); 
      }
  
}