import { Suspense, lazy } from 'react';
import { Layout, useLayoutContext } from './layout/Layout';
import { Routes as Switch, Route, Navigate } from 'react-router';
import { getMultipleRoutes, LoadRouteFromServer, routeLoading, Routes } from './Routes';
import { getLoanApplicationRoutes } from './loan-application/LoanApplicationRoutes';
import { getLoanRoutes } from './loan/LoanRoutes';
import { getUserRoutes } from './user/UserRoutes';
import { useNavigation } from './shared/useNavigation';
import { useGraphQL } from './shared/useGraphQL';
import { getCommunicationRoutes } from './communication/CommunicationRoutes';
import { Brands } from './Brand';
import { isProduction } from './shared/isProduction';

const LazyDecisionLogicVerification = lazy(() => import('./decision-logic/verification/DecisionLogicVerificationSection'));
const LazyDecisionLogicPass = lazy(() => import('./decision-logic/pass/DecisionLogicPassSection'));
const LazyDecisionLogicFail = lazy(() => import('./decision-logic/fail/DecisionLogicFailSection'));
const LazyEmailVerification = lazy(() => import('./email-verification/email-verification-section'));
const LazyPaperless = lazy(() => import('./paperless/paperless-section'));
const LazyStoreApply = lazy(() => import('./store-apply/StoreApply'));
const LazyQuickPay = lazy(() => import('./loan/quick-pay/QuickPaySection'));
const LazyContactSupport = lazy(() => import('./contact-support/ContactSupport'));
const LazyPaystub = lazy(() => import('./paystub/PaystubSection'));
const LazySpreedlyToken = lazy(() => import('./spreedly/SpreedlyTokenSection'));
const LazyVerifyPhoneRedirect = lazy(() => import('./loan-application/phone-verification/verify-phone-redirect'));
const LazyYodleeVerification = lazy(() => import('./yodlee/verification/YodleeVerificationSection'));
const LazyYodleePass = lazy(() => import('./yodlee/pass/YodleePassSection'));
const LazyYodleeFail = lazy(() => import('./yodlee/fail/YodleeFailSection'));

function AppInner() {
    const { brand } = useLayoutContext();
    return (
        <Switch>
            <Route path={Routes.Testing.IntegrationTests} element={isProduction ? <Navigate replace to={"/"} /> : routeLoading} />
            <Route path={Routes.Pay} element={<LazyQuickPay />} />
            <Route path={Routes.PhoneVerificationRedirect} element={<LazyVerifyPhoneRedirect />} />
            <Route path={Routes.EmailVerification} element={<LazyEmailVerification />} />
            <Route path={Routes.SpreedlyToken} element={<LazySpreedlyToken />} />
            {getMultipleRoutes([Routes.ContactSupport.WithResourceKey, Routes.ContactSupport.Root], <LazyContactSupport />)}

            {getUserRoutes(brand)}
            {getLoanApplicationRoutes(brand)}
            {getLoanRoutes(brand)}
            {getCommunicationRoutes(brand)}

            <Route path={Routes.DecisionLogic.Verification} element={<LazyDecisionLogicVerification />} />
            <Route path={Routes.DecisionLogic.Pass} element={<LazyDecisionLogicPass />} />
            <Route path={Routes.DecisionLogic.Fail} element={<LazyDecisionLogicFail />} />
            <Route path={Routes.GoPaperless} element={<LazyPaperless />} />
            {getMultipleRoutes([Routes.StoreApply.WithState, Routes.StoreApply.Root], <LazyStoreApply />)}
            <Route path={Routes.Paystub} element={<LazyPaystub />} />
            <Route path={Routes.Yodlee.Verification} element={<LazyYodleeVerification />} />
            <Route path={Routes.Yodlee.Pass} element={<LazyYodleePass />} />
            <Route path={Routes.Yodlee.Fail} element={<LazyYodleeFail />} />
            <Route index element={<LoadRouteFromServer />} />
        </Switch>
    );
}

export function App({ brand }: { brand?: Brands }) {
    const nav = useNavigation();
    const client = useGraphQL();

    try {
        return (
            <Suspense fallback={routeLoading}>
                <Layout brand={brand}>
                    <Suspense fallback={routeLoading}>
                        <AppInner />
                    </Suspense>
                </Layout>
            </Suspense>
        );
    } catch (ex) {
        const { location } = window;
        const error = ex instanceof Error ? ex : new Error(String(ex));
        client.log(`${location.pathname}: ${error.message}`, [
            {
                key: 'Request.Url',
                value: location.toString(),
            },
            {
                key: 'ExceptionText',
                value: error.stack ?? '',
            },
        ]);
        nav.redirect(Routes.ContactSupport.Root);
        return null;
    }
}
