import { useEffect } from 'react';
import { Route } from 'react-router';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from './shared/Spinner';

export const Routes = {
    AdAstra: {
        Pay: '/AdAstra/Pay',
        ContactUs: '/AdAstra/ContactUs',
        MakePayment: '/AdAstra/Loan/:loanKey/MakePayment',
    },
    Communication: {
        Consents: '/Communication/Consents',
        Email: {
            Root: '/Communication/Email',
            WithKey: '/Communication/Email/:emailKey',
        },
        History: '/Communication/History',
        Preferences: {
            Root: '/Communication/Preferences',
            WithMessageId: '/Communication/Preferences/:messageId',
        },
        Unsubscribe: { WithMessageId: '/Communication/Unsubscribe/:messageId', Root: '/Communication/Unsubscribe' },
        UnsubscribeConfirmation: '/Communication/UnsubscribeConfirmation',
    },
    ConsumerPrivacy: '/ConsumerPrivacy',
    ContactSupport: {
        Root: '/ContactSupport',
        WithResourceKey: '/ContactSupport/:resourceKey',
    },
    ContactUs: '/Contact-Us',
    Content: {
        ConsentToElectronicDisclosure: '/Content/ConsentToElectronicDisclosure'
    },
    DecisionLogic: {
        Fail: '/DecisionLogic/Fail/:workflow',
        Pass: '/DecisionLogic/Pass/:workflow',
        Verification: '/DecisionLogic/Verification/:workflow',
    },
    Documents: {
        FaxCoverSheet: '/Documents/FaxCoverSheet.pdf',
        OptionalPromotionsAndDiscounts: '/Content/pdf/OptionalPromotionsAndDiscounts.pdf',
        SampleLoanPaymentAuthorization: '/Documents/SampleLoanPaymentAuthorization.pdf'
    },
    EmailVerification: '/EmailVerification',
    GoPaperless: '/GoPaperless',
    Loan: {
        AmortizationSchedule: '/Loan/:key/AmortizationSchedule',
        AutoPayEnrollment: '/Loan/:key/AutoPayEnrollment',
        BuyUp: '/Loan/:key/BuyUp',
        CancelPayment: '/Loan/:key/CancelPayment',
        Complete: '/Loan/:key/Complete',
        Documents: '/Loan/Documents',
        Funded: '/Loan/Funded',
        GetCash: '/Loan/:key/GetCash',
        IndividualScheduledPayments: '/Loan/:key/IndividualScheduledPayments',
        MakePayment: '/Loan/:loanKey/MakePayment',
        OutOfWallet: '/Loan/:key/OutOfWallet',
        OutOfWalletTesting: '/Loan/:key/OutOfWallet/Override',
        PaymentPlanRequest: '/Loan/:key/PaymentPlanRequest',
        PaymentPlanRequestComplete: '/Loan/:key/PaymentPlanRequest/Complete',
        PaymentReauthorization: '/Loan/:loanKey/:rtgs/PaymentReauthorization',
        PendingMilitaryReview: '/Loan/PendingMilitaryReview',
        Refinance: '/Loan/:key/Refinance',
        RefinanceIncomeVerification: '/Loan/:key/Refinance/MoreInformation',
        RefinanceDisclosures: '/Loan/RefinanceDisclosures',
        Repayment: '/Loan/:key/Repayment'
    },
    LoanApplication: {
        Account: '/LoanApplication/Account',
        AffiliateAccount: 'LoanApplication/Account2',
        Business: '/LoanApplication/Business',
        Personal: '/LoanApplication/Personal',
        Bank: '/LoanApplication/Bank',
        BankVerification: {
            QuickVerification: 'LoanApplication/Bank/QuickVerification',
            Verification: '/LoanApplication/Bank/Verification',
            Pass: '/LoanApplication/Bank/Verification/Pass/',
            Fail: '/LoanApplication/Bank/Verification/Fail',
        },
        CreditFreeze: '/LoanApplication/CreditFreeze',
        CreditLocked: '/LoanApplication/CreditLocked',
        Documents: '/LoanApplication/Documents',
        Denied: '/LoanApplication/Denied',
        Host: '/LoanApplication/Host',
        Income: '/LoanApplication/Income',
        Index: '/LoanApplication',
        IndividualScheduledPayments: '/LoanApplication/IndividualScheduledPayments',
        NonMarketLocation: '/LoanApplication/NonMarketLocation',
        MilitaryStatus: '/LoanApplication/MilitaryStatus',
        OptionalPaymentAuthorization: '/LoanApplication/OptionalPaymentAuthorization',
        OutOfWallet: '/LoanApplication/OutOfWallet',
        OutOfWalletTesting: '/LoanApplication/OutOfWallet/Override',
        Province: '/LoanApplication/Province',
        PreLoanAppDisclosures: '/LoanApplication/PreLoanAppDisclosures',
        Pending: '/LoanApplication/MoreInformation',
        PendingMilitaryReview: '/LoanApplication/PendingMilitaryReview',
        PhoneVerification: {
            Index: '/LoanApplication/PhoneVerification'
        },
        Process: {
            Index: '/LoanApplication/Process',
            Outage: '/LoanApplication/Process/Outage',
        },
        ProductSelection: '/LoanApplication/ProductSelection',
        ProtectionPlan: '/LoanApplication/ProtectionPlan',
        Review: '/LoanApplication/Review',
        ReviewDisclosures: '/LoanApplication/ReviewDisclosures',
        SelectTerms: '/LoanApplication/SelectTerms',
        SKLoanAppDisclosure: '/LoanApplication/SKLoanAppDisclosure',
        Spouse: '/LoanApplication/Spouse',
        Vehicle: '/LoanApplication/Vehicle',
    },
    Pay: '/Pay',
    PaymentReauthorization: {
        FindAccount: '/paymentReauthorization/findAccount',
    },
    Paystub: '/Paystub',
    PhoneVerificationRedirect: '/LoanApplication/VerifyPhoneRedirect',
    SpreedlyToken: '/SpreedlyToken/:creditCardKey',
    StoreApply: {
        WithState: '/StoreApply/:state',
        Root: '/StoreApply',
    },
    Testing: {
        IntegrationTests: '/testing/integration-tests/:testName',
    },
    User: {
        AccountSummary: '/User',
        AccountSetup: '/User/AccountSetup',
        EditAccountSecurity: '/User/EditAccountSecurity',
        EditBanking: {
            AdditionalAction: '/User/EditBanking/AdditionalAction',
            ConfirmBankingInformation: '/User/EditBanking/ConfirmBankingInformation',
            Documents: '/User/EditBanking/Documents',
            Root: '/User/EditBanking',
            PreAuthorizedDebitChangeForm: '/User/EditBanking/PreAuthorizedDebitChangeForm.pdf',
            WithKey: '/User/EditBanking/:cardKey',
        },
        EditContactInfo: '/User/EditContactInfo',
        EditSpouse: '/User/EditSpouse',
        Login: '/User/Login',
        MessageAuthentication: '/User/MessageAuthentication',
        Logout: '/User/Logout',
        ResetPassword: '/User/ResetPassword',
        RetrievePassword: '/User/RetrievePassword',
    },
    Yodlee: {
        Fail: '/Yodlee/Fail/:workflow',
        Pass: '/Yodlee/Pass/:workflow',
        Verification: '/Yodlee/Verification/:workflow',
    },
};

export const getPaymentReauthorizationRoute = (loanKey: number, returnToGoodStanding: boolean) =>
    getRouteUrl(Routes.Loan.PaymentReauthorization, { loanKey, rtgs: returnToGoodStanding });

export const getCommunicationHistoryRoute = (messageId: string | undefined) => getRouteUrl(Routes.Communication.History, { messageId });

const toString = (x: unknown | undefined) => (x === null || x === undefined ? '' : `/${x}`);

export function getMultipleRoutes(paths: string[], element: Parameters<typeof Route>[0]['element']) {
    return paths.map((path) => <Route key={path} path={path} element={element} />);
}

export const getRouteUrl = (path: string, parameters?: Record<string, unknown>) => {
    const keys = new Set(Object.keys(parameters ?? {}));
    const generated = path.replace(/\/:(\w+)\??/g, (_, name) => {
        keys.delete(name);
        return toString(parameters?.[name]);
    });
    const query =
        keys.size > 0
            ? `?${Array.from(keys)
                  .filter((key) => parameters?.[key] !== undefined)
                  .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(String(parameters?.[key])))
                  .join('&')}`
            : '';
    return generated + query;
};

export const routeLoading = <Spinner className="my-5 h2" />;

export const LoadRouteFromServer = () => {
    const navigate = useNavigate();
    const [query] = useSearchParams();

    useEffect(() => {
        if (query.has('spa')) {
            navigate('/', { replace: true });
        } else if (!process.env.JEST_WORKER_ID) {
            // try redirecting to the path... it could be that the SPA is trying to handle
            // a page that has not been converted to a SPA page.
            query.set('spa', '');
            const search = query.toString();
            window.location.href = window.location.pathname + (search ? '?' : '') + search;
        }
    }, [query, navigate]);
    return routeLoading;
};
